import React from "react";
import { Menu as AntdMenu, Collapse } from "antd";
import { Link } from "react-router-dom";
import {
  IconPencil,
  IconUsers,
  IconUsersGroup,
  IconBuilding,
  IconPuzzle,
  IconTag,
  IconBolt,
  IconSubtask,
  IconTemplate,
} from "@tabler/icons-react";
import "./Menu.scss";

const getItem = (label, key, icon, children) => ({
  key,
  icon,
  children,
  label,
});

function Menu(props) {
  const isPartner = props.user?.parent_company ? true : false;

  let configItems = [
    getItem(
      <Link to="/settings/task-templates">Task Templates</Link>,
      "task-templates",
      <IconTemplate />,
    ),
    getItem(
      <Link to="/settings/workflows">Workflows</Link>,
      "workflows",
      <IconSubtask />,
    ),
    getItem(<Link to="/settings/tags">Tags</Link>, "tags", <IconTag />),
    getItem(
      <Link to="/settings/triggers">Triggers</Link>,
      "triggers",
      <IconBolt />,
    ),
    getItem(
      <Link to="/settings/integrations">Integrations</Link>,
      "integrations",
      <IconPuzzle />,
    ),
  ];

  let manageItems = [
    getItem(
      <Link to="/settings/manage">Manage Company</Link>,
      "manage",
      <IconPencil />,
    ),
    getItem(
      <Link to="/settings/users">User Management</Link>,
      "users",
      <IconUsers />,
    ),
    getItem(
      <Link to="/settings/partners">Partners</Link>,
      "partners",
      <IconBuilding />,
    ),
    getItem(
      <Link to="/settings/teams">Teams</Link>,
      "teams",
      <IconUsersGroup />,
    ),
  ];

  if (isPartner) {
    manageItems = manageItems.filter((i) => !["partners"].includes(i.key));
  }

  if (props.readOnly) {
    configItems = configItems.filter((i) => !["integrations"].includes(i.key));
  }

  // if (configItems) {
  //   configItems = configItems.filter((i) => !["triggers"].includes(i.key));
  // }

  let menuKeys = [props.tab || "manage"];
  if (props.tab === "edit-workflow") {
    menuKeys = ["workflows"];
  }

  const collapseItems = [
    {
      key: "management",
      label: "Management",
      children: (
        <AntdMenu
          theme="light"
          mode="inline"
          selectedKeys={menuKeys}
          items={[...manageItems]}
        />
      ),
    },
    {
      key: "configuration",
      label: "Configuration",
      children: (
        <AntdMenu
          theme="light"
          mode="inline"
          selectedKeys={menuKeys}
          items={[...configItems]}
        />
      ),
    },
  ];

  return (
    <div className="settings-menu">
      <div className="am-title">
        <h3>Settings</h3>
      </div>
      <Collapse
        items={collapseItems}
        defaultActiveKey={["management", "configuration"]}
      />
    </div>
  );
}

export default Menu;
