import { useState } from "react";
import { Button, Modal } from "@centrate-io/barn";
import { IconBraces } from "@tabler/icons-react";
import { FormInput } from "_styleguide";
import "./EditVariables.scss";

function EditVariables(props) {
  const [visible, setVisible] = useState(true);
  const [variables, setVariables] = useState(
    props.variables?.length > 0 ? [...props.variables] : [],
  );

  // Save variables
  const saveForm = async () => {
    props.saveVariables(variables);
    setVisible(false);
  };

  const updateForm = (field, value) => {
    const editedform = [...variables];
    editedform[field].value = value;
    setVariables(editedform);
  };

  return (
    <Modal
      wrapClassName="edit-variables-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={600}
    >
      <div className="pp-modal-header">
        <div>
          <IconBraces />
        </div>
        <h3>Update Variables</h3>
      </div>

      <Modal.Body>
        {variables?.map((v, i) => {
          return (
            <div key={`mail-var-${i}`} className="form-row">
              <span className="variable-name">{`{{${v.variable}}}`}</span>
              <FormInput
                value={v.value}
                onChange={(e) => updateForm(i, e.target.value)}
                size="small"
              />
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Actions>
        <Button
          block
          className="boxed"
          type="secondary"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        <Button block type="primary" className="boxed" onClick={saveForm}>
          Save Variables
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EditVariables;
