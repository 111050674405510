import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Tabs } from "_styleguide";
import {
  IconPhone,
  IconMail,
  IconMessage,
  IconSubtask,
  IconMailbox,
  IconTemplate,
} from "@tabler/icons-react";
import TemplateTable from "./_components/TemplateTable/TemplateTable";
import PostGridTable from "./_components/PostGridTable/PostGridTable";
import { getTemplateCounts } from "App/Admin/_graphql/queries/task";
import "./TaskTemplates.scss";

function TaskTemplates(props) {
  const client = useApolloClient();
  const [tab, setTab] = useState("call");
  const [counts, setCounts] = useState();

  const fetchCounts = async () => {
    const queryData = await client.query({
      query: getTemplateCounts,
    });
    setCounts(queryData?.data?.getTemplateCounts || {});
  };

  const abstractProps = {
    ...props,
    fetchCounts,
  };

  let items = [
    {
      key: "call",
      label: (
        <div className="tab-name call">
          <IconPhone />
          Call{counts ? ` (${counts.call || 0})` : null}
        </div>
      ),
      children: <TemplateTable category="call" {...abstractProps} />,
    },
    {
      key: "general",
      label: (
        <div className="tab-name general">
          <IconSubtask />
          General{counts ? ` (${counts.general || 0})` : null}
        </div>
      ),
      children: <TemplateTable category="general" {...abstractProps} />,
    },
    {
      key: "email",
      label: (
        <div className="tab-name email">
          <IconMail />
          Email{counts ? ` (${counts.email || 0})` : null}
        </div>
      ),
      children: <TemplateTable category="email" {...abstractProps} />,
    },
    {
      key: "text",
      label: (
        <div className="tab-name text">
          <IconMessage />
          Text Message{counts ? ` (${counts.text || 0})` : null}
        </div>
      ),
      children: <TemplateTable category="text" {...abstractProps} />,
    },
    {
      key: "mail",
      label: (
        <div className="tab-name mail">
          <IconMailbox />
          Mail{counts ? ` (${counts.mail || 0})` : null}
        </div>
      ),
      children: <PostGridTable category="mail" {...abstractProps} />,
    },
  ];

  useEffect(() => {
    props.setCrumbs({
      title: "Manage Templates",
      icon: <IconTemplate />,
      description:
        "Automate and improve your task process by using templates...",
    });
    props?.setCurrentCount(0);
    fetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-task-templates task-template-tabs">
      <Tabs
        onChange={(key) => {
          setTab(key);
        }}
        activeKey={tab}
        items={items}
        className={tab}
        destroyInactiveTabPane={true}
      />
    </div>
  );
}

export default TaskTemplates;
