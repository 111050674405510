import React, { useState, useEffect } from "react";
import { Table, Empty, Loading } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getColumns } from "./_data/Columns";
import { Button, message } from "@centrate-io/barn";
import { IconPlus } from "@tabler/icons-react";
import { getPostGridTemplates } from "App/Admin/_graphql/queries/postgrid";
import PostGridModal from "../../_modals/PostGridModal/PostGridModal";
import { getErrors } from "_helpers/api";
import "./PostGridTable.scss";

function PostGridTable(props) {
  const client = useApolloClient();
  const [tasks, setTasks] = useState();
  const [loading, setLoading] = useState(true);
  const [templateModal, setTemplateModal] = useState(false);

  const fetchTasks = async (initial = false) => {
    try {
      const queryData = await client.query({
        query: getPostGridTemplates,
      });
      if (!queryData?.data?.getPostGridTemplates)
        throw new Error(queryData?.errors?.[0]?.message);
      setLoading(false);
      setTasks(queryData?.data?.getPostGridTemplates || []);
      if (props.fetchCounts && !initial) props.fetchCounts();
    } catch (err) {
      message.error(
        getErrors(err) || "Error getting mail templates, try again",
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = tasks || [];
  const isEmpty = (tasks || [])?.length <= 0;

  const showNewTask = (template) => {
    setTimeout(() => {
      setTemplateModal(template ? template : true);
    }, 1);
  };
  const editTemplate = (template) => {
    showNewTask({
      id: template.id,
      title: template.title,
      type: "mail",
      instructions: template.instructions,
      subject: null,
      variables: template.variables,
      is_html: true,
    });
  };
  const columns = getColumns(props.readOnly, showNewTask, editTemplate);

  const categoryClean =
    props.category?.charAt(0).toUpperCase() + props.category?.slice(1);

  const openInNewTab = () => {
    window.open(
      "https://dashboard.postgrid.com/dashboard/templates",
      "_blank",
      "noopener,noreferrer",
    );
  };

  return loading ? (
    <div className="settings-integrations">
      <Loading />
    </div>
  ) : (
    <>
      {templateModal ? (
        <PostGridModal
          {...props}
          reload={fetchTasks}
          template={templateModal}
          type={props.category}
          removeModal={() => setTemplateModal(false)}
        />
      ) : null}
      {isEmpty ? (
        <>
          <Empty
            title={`Create Your First ${categoryClean} Template`}
            description={`${categoryClean} templates can help you build your workflows and automate your work more efficiently...`}
            action={`Create ${categoryClean} Template`}
            actionIcon={<IconPlus />}
            onClick={openInNewTab}
          />
        </>
      ) : (
        <div className="settings-template-table">
          <div className="settings-search">
            <div className="ms-filters"></div>
            <div className="ms-actions">
              {!props.readOnly ? (
                <Button type="primary" size="small" onClick={openInNewTab}>
                  <IconPlus />
                  {categoryClean} Template
                </Button>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            loading={loading}
            pagination={{ pageSize: 10, showSizeChanger: false }}
            rowKey={(record) => record.id}
            dataSource={tableData}
            showSorterTooltip={false}
            scroll={{
              y: "calc(80vh - 231px)",
              x: "max-content",
            }}
            locale={{
              emptyText: loading
                ? "Loading Mail templates..."
                : "No Mail templates found",
            }}
          />
        </div>
      )}
    </>
  );
}

export default PostGridTable;
