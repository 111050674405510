import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, Modal, message } from "@centrate-io/barn";
import { IconCards } from "@tabler/icons-react";
import TemplateMapping from "./_components/TemplateMapping/TemplateMapping";
import TemplatePreview from "./_components/TemplatePreview/TemplatePreview";
import { extractVariables } from "_assets/js/helpers";
import { saveTaskTemplate } from "App/Admin/_graphql/mutations/postgrid";
import { getVariableMapping } from "App/Admin/_graphql/queries/variable_mapping";
import { Loading } from "_styleguide";
import { getErrors } from "_helpers/api";
import "./PostGridModal.scss";

function PostGridModal(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("mapping"); // mapping > preview
  const [form, setForm] = useState();

  const fetchVariables = async () => {
    try {
      const queryData = await client.query({
        variables: {
          item_id: props.template?.id,
        },
        query: getVariableMapping,
      });
      const variableData = queryData?.data?.getVariableMapping?.variables || [];
      const instructionsData = props.template.instructions;
      setForm({
        id: props.template?.id,
        variables: goToVariables(instructionsData, variableData),
        instructions: instructionsData,
      });
      setLoading(false);
    } catch (err) {
      message.error(getErrors(err) || "Error getting mail template, try again");
      setLoading(false);
    }
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  // Create Task Template
  const createTemplate = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: { ...form },
        mutation: saveTaskTemplate,
      });
      message.success(`Mail template saved`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error saving template, try again");
      setSaving(false);
    }
  };

  const formProps = {
    form,
    updateForm,
    saving,
  };

  const goToVariables = (formInstructions, formVariables) => {
    const variables = extractVariables(formInstructions);
    const variableMapping = variables.map((v) => {
      const findExisting = formVariables.find((o) => o.variable === v);
      if (findExisting) return findExisting;
      return {
        variable: v,
        mapping: undefined,
      };
    });
    return variableMapping || [];
  };

  const goBack = () => {
    if (tab === "preview" && form.variables.length > 0) {
      setTab("mapping");
    } else {
      setTab("mapping");
    }
  };

  const titles = {
    content: `Edit Template Content`,
    mapping: `Variable Mapping`,
    preview: `Mail Preview`,
  };

  useEffect(() => {
    fetchVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      wrapClassName="create-template-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={800}
    >
      <div className="pp-modal-header">
        <div>
          <IconCards />
        </div>
        <h3>{titles[tab]}</h3>
      </div>

      {loading ? (
        <div style={{ position: "relative", minHeight: "300px" }}>
          <Loading />
        </div>
      ) : (
        <>
          <Modal.Body>
            {tab === "mapping" ? <TemplateMapping {...formProps} /> : null}
            {tab === "preview" ? <TemplatePreview {...formProps} /> : null}
          </Modal.Body>

          <Modal.Actions>
            <div>
              {tab === "preview" ? (
                <Button disabled={saving} type="blanco" onClick={goBack}>
                  Previous
                </Button>
              ) : (
                <Button
                  disabled={saving}
                  type="secondary"
                  onClick={() => setVisible(false)}
                >
                  Cancel
                </Button>
              )}
            </div>
            <div>
              {tab === "preview" ? (
                <Button
                  type="primary"
                  onClick={createTemplate}
                  loading={saving}
                >
                  Save Mapping
                </Button>
              ) : null}
              {tab === "mapping" ? (
                <Button type="primary" onClick={() => setTab("preview")}>
                  Next Step
                </Button>
              ) : null}
            </div>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default PostGridModal;
