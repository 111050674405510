import axios from "axios";
import { callsApi } from "_helpers/api";

export const getToken = async () => {
  let token = null;
  try {
    const results = await axios.get(`${callsApi()}/twilio/token`, {
      headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
    });
    token = results?.data?.token || null;
  } catch (err) {
    console.error(err);
  }
  return token;
};
