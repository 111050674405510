import React from "react";
import { IconMailbox } from "@tabler/icons-react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { mailStatusMap } from "_constants/twilio";

export default function Mail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { activity } = props;

  const status = activity.mail;
  let icon = <IconMailbox />;

  const goToMail = () => {
    navigate(`/customer/${params.id}/mail`);
    props.setTab("mail");
  };

  return (
    <div className={"app-activity status-"}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {status.status === "printing" && (
          <React.Fragment>
            Letter <b>({status.description || "N/A"})</b> is now{" "}
            <b>{mailStatusMap[status.status]}</b>
          </React.Fragment>
        )}
        {status.status === "processed_for_delivery" && (
          <React.Fragment>
            Letter <b>({status.description || "N/A"})</b> is now{" "}
            <b>{mailStatusMap[status.status]}</b>
          </React.Fragment>
        )}
        {status.status === "completed" && (
          <React.Fragment>
            Letter <b>({status.description || "N/A"})</b> is now{" "}
            <b>{mailStatusMap[status.status]}</b>
          </React.Fragment>
        )}
        {status.status === "cancelled" && (
          <React.Fragment>
            Letter <b>({status.description || "N/A"})</b> was{" "}
            <b>{mailStatusMap[status.status]}</b>
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
        <div className="pp-link" onClick={goToMail}>
          See All Mail
        </div>
      </div>
    </div>
  );
}

//
