import React, { useState, useEffect } from "react";
import {
  Search,
  Table,
  TaskBadge,
  TimeRange,
  Tag,
  SelectUsers,
} from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminTasks, adminTaskStats } from "App/Admin/_graphql/queries/task";
import { getColumns } from "./_data/Columns";
import { getCrumbs } from "../_helpers/crumbs";
import dayjs from "dayjs";
import StatBar from "./_components/StatBar/StatBar";
import { Select } from "antd";
import { IconCheck, IconRotateClockwise2 } from "@tabler/icons-react";
import { Modal, message } from "@centrate-io/barn";
import { deleteTaskCustomer } from "App/Admin/_graphql/mutations/task";
import { getErrors } from "_helpers/api";
import CompleteTask from "App/Admin/Application/_components/Tasks/_components/CompleteTask/CompleteTask";
import { useIntegrations } from "_contexts/useIntegrations";
import "./Tasks.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "due_timestamp",
  sortOrder: "ascend",
};

function Tasks(props) {
  const client = useApolloClient();
  const { integrations } = useIntegrations();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [completeModal, setCompleteModal] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [stats, setStats] = useState({});
  const [taskType, setTaskType] = useState("all");
  const [type, setType] = useState("all");
  const [date, setDate] = useState(dayjs().format("YYYYMMDD"));
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("all");
  const [search, setSearch] = useState();
  const [assigned, setAssigned] = useState({
    assignedUser: null,
    assignedTeam: null,
  });

  // Load Payments
  const fetchTasks = async (params = defaultParams, pageChange = true) => {
    const queryData = await client.query({
      variables: {
        id: props?.applicationId,
        ...params,
        date: date,
        type: type,
        search: search,
        status: status,
        assignedUser: assigned.assignedUser,
        assignedTeam: assigned.assignedTeam,
        taskType: taskType,
      },
      query: adminTasks,
    });
    const pagination = { ...state.pagination };
    pagination.total = queryData?.data?.adminTasks?.pageTotal;
    pagination.totalAll = queryData?.data?.adminTasks?.total;
    setPage(params?.page || 1);
    props?.setCurrentCount(pagination.totalAll);
    setState({
      loading: false,
      data: queryData?.data?.adminTasks?.data || [],
      pagination: pagination,
    });
    if (!pageChange) fetchStats();
  };

  // Load stats
  const fetchStats = async () => {
    setLoadingStats(true);
    const queryData = await client.query({
      variables: {
        date: date,
        type: type,
        search: search,
        status: status,
        assignedUser: assigned.assignedUser,
        assignedTeam: assigned.assignedTeam,
        taskType: taskType,
      },
      query: adminTaskStats,
    });
    const statData = queryData?.data?.adminTaskStats;
    setStats(statData);
    setLoadingStats(false);
  };

  const removeCustomerTask = async (task_customer_id) => {
    Modal.confirm({
      title: "Are you sure you want remove this task?",
      okText: "Remove Task",
      content:
        "This action cannot be reversed and will remove the task. If needed, you can add a manual task to replace this one if done accidentally.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: { task_customer_id },
            mutation: deleteTaskCustomer,
          });
          message.success(`Task has been removed`);
          fetchTasks();
        } catch (err) {
          message.error(getErrors(err) || "Error removing task, try again");
        }
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchTasks(
      {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        search: search,
        date: date,
        type: type,
        status: status,
        assignedUser: assigned.assignedUser,
        assignedTeam: assigned.assignedTeam,
        taskType: taskType,
      },
      true,
    );
  };
  const columns = getColumns(
    removeCustomerTask,
    setCompleteModal,
    integrations,
  );

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("tasks"));
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, type, status, assigned, taskType]);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  const timeProps = { type, setType, date, setDate };

  return (
    <div className="admin-table-tasks">
      {completeModal ? (
        <CompleteTask
          taskId={completeModal}
          reload={fetchTasks}
          removeModal={() => setCompleteModal(false)}
        />
      ) : null}
      <div className="admin-search admin-search-row">
        <Select
          value={status}
          onChange={(newType) => setStatus(newType)}
          className="filter-select task-status-select"
          popupClassName="pp-popup task-status-popup"
          placeholder="All Statuses"
        >
          <Select.Option value="all">All Statuses</Select.Option>
          <Select.Option value="due">
            <Tag type="primary">Due now</Tag>
          </Select.Option>
          <Select.Option value="upcoming">
            <Tag>Upcoming</Tag>
          </Select.Option>
          <Select.Option value="overdue">
            <Tag type="warning">Overdue</Tag>
          </Select.Option>
          <Select.Option value="completed">
            <Tag type="success">
              <IconCheck />
              Completed
            </Tag>
          </Select.Option>
          <Select.Option value="skipped">
            <Tag type="neutral">
              <IconRotateClockwise2 />
              Skipped
            </Tag>
          </Select.Option>
        </Select>
        <Select
          value={taskType}
          onChange={(val) => setTaskType(val)}
          className="filter-select filter-tasks-type"
          popupClassName="pp-select-dropdown ppsd-task"
          placeholder="All Types"
        >
          <Select.Option value="all">All Types</Select.Option>
          <Select.Option value="general">
            <TaskBadge type="general" />
            General
          </Select.Option>
          <Select.Option value="call">
            <TaskBadge type="call" />
            Call
          </Select.Option>
          <Select.Option value="email">
            <TaskBadge type="email" />
            Email
          </Select.Option>
          <Select.Option value="text">
            <TaskBadge type="text" />
            Text
          </Select.Option>
          <Select.Option value="mail">
            <TaskBadge type="mail" />
            Mail
          </Select.Option>
        </Select>
        <SelectUsers {...assigned} updateAssigned={setAssigned} />
        <div className="admin-search-sep" />
        <TimeRange {...timeProps} />
        <div className="admin-search-sep" />
        <Search
          placeholder="Search tasks..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchTasks({ ...defaultParams, search });
          }}
          style={{ width: 220 }}
        />
      </div>
      <div className="admin-table application-table">
        {false && <StatBar stats={stats} loadingStats={loadingStats} />}
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 317px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading payments..."
              : "No payments found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default Tasks;
