import React, { useState, useEffect } from "react";
import { Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminApplicationDocuments } from "App/Admin/_graphql/queries/document";
import { removeDocument } from "App/Admin/_graphql/mutations/document";
import { getColumns } from "./_data/Columns";
import { Button, Modal, message } from "@centrate-io/barn";
import { Upload } from "antd";
import { getErrors } from "_helpers/api";
import { baseApi } from "_helpers/api";
import "./Documents.scss";

function Documents(props) {
  const client = useApolloClient();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });

  // Load Payments
  const fetchDocuments = async () => {
    const queryData = await client.query({
      variables: {
        application_id: props?.applicationId,
      },
      query: adminApplicationDocuments,
    });
    setState({
      loading: false,
      data: queryData?.data?.adminApplicationDocuments || [],
    });
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadProps = {
    name: "file",
    action: `${baseApi()}/application/documents`,
    multiple: true,
    showUploadList: false,
    accept: "application/pdf",
    data: {
      application_id: props?.applicationId,
      user_id: props?.user?.id,
      company_id: props?.user?.company?.id,
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        fetchDocuments();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // Remove Bank Account
  const deleteDocument = async (document_id) => {
    Modal.confirm({
      title: "Are you sure you want remove this document?",
      okText: "Remove Document",
      content:
        "Warning: this cannot be undone, please only delete documents if you are sure you need to remove it.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              document_id: document_id,
            },
            mutation: removeDocument,
          });
          message.success(`Document successfully removed`);
          fetchDocuments();
        } catch (err) {
          console.log(err);
          message.error(getErrors(err) || "Error removing document, try again");
        }
      },
    });
  };
  const columns = getColumns(deleteDocument, props.readOnly);

  return (
    <div className="admin-application-documents">
      {!props.readOnly ? (
        <div className="manage-search">
          <div className="ms-actions"></div>
          <div className="ms-filters">
            <Upload {...uploadProps}>
              <Button type="primary" size="small">
                + Add Documents
              </Button>
            </Upload>
          </div>
        </div>
      ) : null}
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 276px - 40px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading documents..."
              : "No documents found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          {/*Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}*/}
        </div>
      )}
    </div>
  );
}

export default Documents;
