import React, { useState, useEffect } from "react";
import { message } from "@centrate-io/barn";
import { timeout } from "_assets/js/helpers";
import CreditLoading from "./_components/CreditLoading/CreditLoading";
import CreditSelect from "./_components/CreditSelect/CreditSelect";
import RunReport from "./_components/RunReport/RunReport";
import VerifySSN from "./_components/VerifySSN/VerifySSN";
import { getErrors } from "_helpers/api";
import axios from "axios";
import { useApolloClient } from "@apollo/client";
import { getCreditState } from "_graphql/queries/application";
import { baseApi } from "_helpers/api";
import "./Credit.scss";

function Credit(props) {
  const { form } = props;
  const [loadingReport, setLoadingReport] = useState();
  const [creditData, setCreditData] = useState();
  const [stateData, setStateData] = useState();
  const [initialLoading, setInitialLoading] = useState(false);
  const [fromLoad, setFromLoad] = useState(false);
  const client = useApolloClient();

  const handleClick = async () => {
    try {
      setLoadingReport(true);
      await timeout(500);
      await axios.post(`${baseApi()}/credit-report`, form);
      await checkState();
    } catch (err) {
      message.error(
        getErrors(err) || "Error checking credit report, try again",
      );
      setLoadingReport(false);
    }
  };

  const checkState = async () => {
    const stateResponse = await client.query({
      query: getCreditState,
      variables: {
        application_id: form?.id,
      },
    });
    const newState = stateResponse?.data?.getCreditState;
    setStateData(newState || undefined);
    if (newState?.state === "select") {
      setFromLoad(true);
      setCreditData(newState?.tradelines);
    }
    setInitialLoading(false);
    setLoadingReport(false);
  };

  useEffect(() => {
    checkState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = loadingReport || initialLoading;
  if (isLoading) {
    return <CreditLoading />;
  } else if (stateData?.state === "credit") {
    return (
      <RunReport
        {...props}
        handleClick={handleClick}
        form={form}
        loadingReport={loadingReport}
      />
    );
  } else if (stateData?.state === "verify") {
    return (
      <VerifySSN
        {...props}
        ssns={stateData?.ssns || []}
        form={form}
        checkState={checkState}
        setLoadingReport={setLoadingReport}
      />
    );
  } else if (stateData?.state === "select") {
    return (
      <CreditSelect
        tradeline={creditData}
        actionsProps={props}
        form={form}
        next={props.next}
        fromLoad={fromLoad}
      />
    );
  }

  return <CreditLoading />;
}

export default Credit;
