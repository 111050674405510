import { useState } from "react";
import { Button, Modal } from "@centrate-io/barn";
import { IconAddressBook } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex, Autocomplete, Select } from "_styleguide";
import { STATES } from "_helpers/location";
import { validateAddressBook } from "_helpers/validateForm";
import SearchAddressBook from "../../_components/SearchAddressBook/SearchAddressBook";
import "./EditAddressModal.scss";

function EditAddressModal(props) {
  const [visible, setVisible] = useState(true);
  const [form, setForm] = useState(props.address || {});

  // Submit question form
  const saveForm = async () => {
    if (props.destination === "to") {
      props.setTo(form);
    } else {
      props.setFrom(form);
    }
    setVisible(false);
  };

  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const changeAddress = (data) => {
    let newForm = { ...data };
    if (newForm.id) delete newForm.id;
    setForm(newForm);
    props.setAddressBook(data);
  };

  const ua = (oldForm, values) => {
    setForm({ ...oldForm, ...values });
  };

  const validation = validateAddressBook(form);

  return (
    <Modal
      wrapClassName="edit-address-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={600}
    >
      <div className="pp-modal-header">
        <div>
          <IconAddressBook />
        </div>
        <h3>Update {props.destination === "to" ? "To" : "From"} Address</h3>
      </div>

      <Modal.Body>
        {props.destination === "to" ? (
          <>
            <Flex vertical={true}>
              <InputLabel htmlFor="name">Start from Address Book</InputLabel>
              <SearchAddressBook
                onSelect={changeAddress}
                value={props.addressBook}
              />
            </Flex>
            <div className="break" />
          </>
        ) : null}

        <Flex vertical={true}>
          <InputLabel htmlFor="name" {...validation.name}>
            Name *
          </InputLabel>
          <FormInput
            id="name"
            value={form.name}
            onChange={(e) => updateForm("name", e.target.value)}
            placeholder="e.g. Chase Bank..."
            size="small"
            {...validation.name}
          />
        </Flex>
        <div className="form-item">
          <InputLabel {...validation.address}>Address *</InputLabel>
          <div className="form-row">
            <Autocomplete
              id="address"
              value={form.address}
              onChange={(v) => updateForm("address", v)}
              form={form}
              updateAddress={ua}
              size="small"
              {...validation.address}
            />
            <FormInput
              id="address_line_two"
              value={form.address_line_two}
              onChange={(e) => updateForm("address_line_two", e.target.value)}
              placeholder="APT, suite, unit"
              size="small"
              style={{ minWidth: "160px", maxWidth: "160px" }}
            />
          </div>
        </div>

        <div className="form-row">
          <div>
            <InputLabel htmlFor="city" {...validation.city}>
              City *
            </InputLabel>
            <FormInput
              id="city"
              value={form.city}
              onChange={(e) => updateForm("city", e.target.value)}
              size="small"
              {...validation.city}
            />
          </div>
          <div style={{ maxWidth: "160px" }}>
            <InputLabel htmlFor="state" {...validation.state}>
              State *
            </InputLabel>
            <Select
              value={form.state}
              onChange={(val) => updateForm("state", val)}
              size="medium"
              popupClassName="pp-select-dropdown"
              {...validation.state}
            >
              {STATES.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ maxWidth: "160px" }}>
            <InputLabel htmlFor="zip" {...validation.zip}>
              Zip *
            </InputLabel>
            <FormInput
              id="zip"
              value={form.zip}
              onChange={(e) => updateForm("zip", e.target.value)}
              size="small"
              maxLength="5"
              {...validation.zip}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <Button
          block
          className="boxed"
          type="secondary"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        <Button
          block
          type="primary"
          className="boxed"
          disabled={!validation.allValid}
          onClick={saveForm}
        >
          Update Address
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EditAddressModal;
