import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { callsApi } from "_helpers/api";

const httpCallsLink = createHttpLink({
  uri: `${callsApi()}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("JWT");
  return {
    headers: { ...headers, Authorization: token ? `JWT ${token}` : "" },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const CallsClient = new ApolloClient({
  link: authLink.concat(httpCallsLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions,
});
