export const TAG_MAP = {
  "customer.name": {
    label: "Customer -- Name",
    example: "Franklin Johnson",
  },
  "customer.first_name": {
    label: "Customer -- First Name",
    example: "Franklin",
  },
  "customer.last_name": {
    label: "Customer -- Last Name",
    example: "Johnson",
  },
  "customer.co_name": {
    label: "Customer -- Co-Appl. Name",
    example: "Barbara Johnson",
  },
  "customer.co_first_name": {
    label: "Customer -- Co-Appl. First Name",
    example: "Barbara",
  },
  "customer.co_last_name": {
    label: "Customer -- Co-Appl. Last Name",
    example: "Johnson",
  },
  "customer.email": {
    label: "Customer -- Email Address",
    example: "frank.johnson@email.com",
  },
  "customer.phone": {
    label: "Customer -- Phone Number",
    example: "555-555-5555",
  },
  "customer.mobile": {
    label: "Customer -- Mobile Number",
    example: "555-666-6666",
  },
  "customer.dob": {
    label: "Customer -- Date of Birth",
    example: "12/12/2012",
  },
  "customer.ssn_last_4": {
    label: "Customer -- SSN Last Four",
    example: "1234",
  },
  "customer.address": {
    label: "Customer -- Address",
    example: "123 Balwdwin Road",
  },
  "customer.address_line_two": {
    label: "Customer -- Address Line Two",
    example: "APT 4A",
  },
  "customer.city": {
    label: "Customer -- City",
    example: "Albany",
  },
  "customer.state": {
    label: "Customer -- State",
    example: "NY",
  },
  "customer.zip": {
    label: "Customer -- Zip Code",
    example: "12084",
  },
  "company.name": {
    label: "Company -- Name",
    example: "PayPath",
  },
  "employee.name": {
    label: "Employee -- Name",
    example: "John Stratton",
  },
  "employee.email": {
    label: "Employee -- Email Address",
    example: "john.stratton@paypath.co",
  },
  "employee.phone": {
    label: "Employee -- Phone Number",
    example: "555-777-7777",
  },
};

export const DATES_TAG_MAP = {
  "date.us": {
    label: "Date (Current) -- US Format",
    example: "02/09/2025",
  },
  "date.full": {
    label: "Date (Current) -- Full Date",
    example: "February 9, 2025",
  },
}
