import { useState, useCallback } from "react";
import { Button, message, Modal } from "@centrate-io/barn";
import { Slider } from "antd";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./crop";
import { IconPhotoEdit, IconPlus } from "@tabler/icons-react";
import axios from "axios";
import { baseApi } from "_helpers/api";
import "./CompanyLogo.scss";

function CreateTeam(props) {
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const triggerFileSelect = () => {
    document.getElementById("fileInput").click();
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveCroppedImage = async () => {
    setSaving(true);
    try {
      // Get the cropped image as a Blob
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);

      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append("logo", croppedImage, "logo.png"); // "logo" matches the multer field name on the server

      // Make an axios request to upload the image
      const response = await axios.post(`${baseApi()}/company-logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "JWT " + localStorage.getItem("JWT"),
        },
      });

      if (response.data.success) {
        message.success("Logo cropped and updated successfully");
        setImage(null);
        if (props.reload) props.reload();
        if (props.fetchLogin) props.fetchLogin();
        setVisible(false);
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Error saving cropped image");
    }
    setSaving(false);
  };

  const closeAndClear = () => {
    setVisible(false);
    setImage(null);
    if (props.removeModal) props.removeModal();
  };

  return (
    <Modal
      wrapClassName="company-logo-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div>
          <IconPhotoEdit />
        </div>
        <h3>Manage Logo</h3>
      </div>

      <Modal.Body>
        {!image ? (
          <div className="image-upload-section">
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }} // Hide the input
            />
            <Button
              className="boxed"
              type="primary"
              onClick={triggerFileSelect}
            >
              <IconPlus />
              Upload Image
            </Button>
          </div>
        ) : null}
        {image && (
          <>
            <div style={{ position: "relative", width: "100%", height: 400 }}>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <Slider
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={setZoom}
              style={{ marginTop: 16 }}
            />
          </>
        )}
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={closeAndClear}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={!image}
          onClick={saveCroppedImage}
          loading={saving}
        >
          Crop and Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateTeam;
