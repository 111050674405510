import React, { useState, useEffect } from "react";
import { Table, Empty, Loading } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getColumns } from "./_data/Columns";
import { Button } from "@centrate-io/barn";
import { IconPlus } from "@tabler/icons-react";
import { getTableTemplates } from "App/Admin/_graphql/queries/task";
import TemplateModal from "../../_modals/TemplateModal/TemplateModal";
import "./TemplateTable.scss";

function TemplateTable(props) {
  const client = useApolloClient();
  const [tasks, setTasks] = useState();
  const [loading, setLoading] = useState(true);
  const [templateModal, setTemplateModal] = useState(false);

  const fetchTasks = async (initial = false) => {
    const queryData = await client.query({
      variables: {
        type: props.category,
      },
      query: getTableTemplates,
    });
    setLoading(false);
    setTasks(queryData?.data?.getTaskTemplates || []);
    if (props.fetchCounts && !initial) props.fetchCounts();
  };

  useEffect(() => {
    fetchTasks(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = tasks || [];
  const isEmpty = (tasks || [])?.length <= 0;

  const showNewTask = (template) => {
    setTimeout(() => {
      setTemplateModal(template ? template : true);
    }, 1);
  };
  const editTemplate = (template) => {
    showNewTask({
      id: template.id,
      title: template.title,
      type: template.type,
      instructions: template.instructions,
      subject: template.subject,
      variables: template.variables,
      is_html: template.is_html,
    });
  };
  const columns = getColumns(props.readOnly, showNewTask, editTemplate);

  const categoryClean =
    props.category?.charAt(0).toUpperCase() + props.category?.slice(1);

  return loading ? (
    <div className="settings-integrations">
      <Loading />
    </div>
  ) : (
    <>
      {templateModal ? (
        <TemplateModal
          {...props}
          reload={fetchTasks}
          template={templateModal}
          type={props.category}
          removeModal={() => setTemplateModal(false)}
        />
      ) : null}
      {isEmpty ? (
        <>
          <Empty
            title={`Create Your First ${categoryClean} Template`}
            description={`${categoryClean} templates can help you build your workflows and automate your work more efficiently...`}
            action={`Create ${categoryClean} Template`}
            actionIcon={<IconPlus />}
            onClick={() => showNewTask(true)}
          />
        </>
      ) : (
        <div className="settings-template-table">
          <div className="settings-search">
            <div className="ms-filters"></div>
            <div className="ms-actions">
              {!props.readOnly ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => showNewTask(true)}
                >
                  <IconPlus />
                  Create {categoryClean} Template
                </Button>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            loading={loading}
            pagination={{ pageSize: 10, showSizeChanger: false }}
            rowKey={(record) => record.id}
            dataSource={tableData}
            showSorterTooltip={false}
            scroll={{
              y: "calc(80vh - 231px)",
              x: "max-content",
            }}
            locale={{
              emptyText: loading ? "Loading tags..." : "No tags found",
            }}
          />
        </div>
      )}
    </>
  );
}

export default TemplateTable;
