import { gql } from "@apollo/client";

export const getVariableMapping = gql`
  query getVariableMapping($item_id: String) {
    getVariableMapping(item_id: $item_id) {
      variables {
        variable
        mapping
      }
    }
  }
`;
