export const statusMap = {
  canceled: "Cancelled",
  completed: "Completed",
  failed: "Call Failed",
  "no-answer": "No Answer",
  busy: "Busy Signal",
};

export const mailStatusMap = {
  ready: "Draft",
  printing: "Printing",
  cancelled: "Cancelled",
  processed_for_delivery: "Processed",
  completed: "Mailed",
};
