import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { Radio } from "antd";
import { getErrors } from "_helpers/api";
import { FormInput, InputLabel, Flex } from "_styleguide";
import { validateAccountRouting } from "App/Admin/_helpers/validateForm";
import PayPathLogo from "_assets/logos/icon.svg";
import PlaidLogo from "_assets/logos/plaid_icon.svg";
import { NumbersOnly } from "_assets/js/helpers";
import { saveManualAccount } from "App/Admin/_graphql/mutations/bank_account";
import {
  IconBuildingBank,
  IconLoader,
  IconCircleCheckFilled,
  IconAlertTriangleFilled,
} from "@tabler/icons-react";
import axios from "axios";
import { baseApi } from "_helpers/api";
import "./AccountRouting.scss";

function AccountRouting(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    account: undefined,
    reAccount: undefined,
    routing: undefined,
    bank: undefined,
    accountType: "checking",
  });
  const [current, setCurrent] = useState({
    loading: false,
    routingDone: false,
    plaidBankName: null,
    plaidBankLogo: null,
    plaidBankUrl: null,
  });

  // Submit question form
  const createBankAccount = async () => {
    setSaving(true);

    const formData = {
      application_id: props.form.id,
      name: current.plaidBankName || form.bank,
      logo: current.plaidBankLogo || null,
      url: current.plaidBankUrl || null,
      account_number: form.account,
      routing_number: form.routing,
      account_type: form.accountType,
    };

    try {
      await client.mutate({
        variables: formData,
        mutation: saveManualAccount,
      });
      message.success(`Bank account added`);
      await props.sendSocketMessage(
        "bank",
        JSON.stringify({
          ...formData,
          last_four: form?.account?.slice(-4),
        }),
      );
      if (props.updateBank) props.updateBank();
      if (props.setBankAdded) props.setBankAdded(true);
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error adding bank account, try again");
      setSaving(false);
    }
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  useEffect(() => {
    const getInstitution = async (routingNumber) => {
      let institutionData = null;
      try {
        institutionData = await axios.get(
          `${baseApi()}/plaid/routing?routing=${routingNumber}&application_id=${props.form.id}`,
        );
      } catch (err) {
        institutionData = null;
      }
      return institutionData;
    };

    const doSomething = async () => {
      if (form.routing?.length === 9) {
        setCurrent({ ...current, loading: true });
        const bankData = await getInstitution(form.routing);
        setCurrent({
          loading: false,
          routingDone: true,
          plaidBankName: bankData?.data?.bank,
          plaidBankLogo: bankData?.data?.logo,
          plaidBankUrl: bankData?.data?.url,
        });
      } else if (form.routing?.length < 9 && current?.routingDone) {
        setCurrent({
          loading: false,
          routingDone: false,
          plaidBankName: null,
          plaidBankLogo: null,
          plaidBankUrl: null,
        });
      }
    };
    doSomething();
  }, [form.routing]); // eslint-disable-line react-hooks/exhaustive-deps
  const validation = validateAccountRouting(form, current);
  const isValid = validation?.allValid ? true : false;
  const noMatch =
    validation?.account?.valid &&
    validation.reAccount.started &&
    form?.account?.length <= form?.reAccount?.length &&
    !validation.reAccount.valid;

  return (
    <Modal
      wrapClassName="account-routing-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.closeWindow}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <Modal.Close onClick={() => setVisible(false)} />

      <div className="connect-icons">
        <div className="customer">
          <img src={PayPathLogo} alt="PayPath" />
        </div>
        <div className="vendor">
          <img src={PlaidLogo} alt="Plaid" />
        </div>
      </div>

      <div className="connect-title">
        We use <b>Plaid</b> to verify bank accounts.
      </div>

      <Modal.Body>
        <div className="ar-form">
          <div className="ar-title">
            <b>Account:</b>&nbsp;{props.form?.first_name}&nbsp;
            {props.form?.last_name}
          </div>
          <div className="ar-inputs">
            <Flex
              className={`routing-box${current.routingDone && !current?.plaidBankName ? " no-bank" : ""}`}
              vertical={true}
            >
              <InputLabel htmlFor="routing" {...validation.routing}>
                Routing Number *
              </InputLabel>
              <div className="route-input">
                <div
                  className={`routing-icon${current?.plaidBankName ? " has-logo" : ""}`}
                >
                  {current.loading ? (
                    <IconLoader />
                  ) : (
                    <>
                      {!current.routingDone || !current.plaidBankLogo ? (
                        <IconBuildingBank />
                      ) : (
                        <img
                          src={`data:image/png;base64, ${current?.plaidBankLogo}`}
                          alt={current?.plaidBankName}
                        />
                      )}
                    </>
                  )}
                </div>
                <FormInput
                  id="routing"
                  size="medium"
                  value={form.routing}
                  onChange={(e) =>
                    updateForm("routing", NumbersOnly(e.target.value))
                  }
                  disabled={current.loading || saving}
                  maxLength={9}
                  placeholder="e.g. 0493857163..."
                  {...validation.routing}
                />
              </div>
              {current?.plaidBankName ? (
                <div className="has-bank">
                  <IconCircleCheckFilled />
                  {current?.plaidBankName}
                </div>
              ) : null}
              {current.routingDone && !current?.plaidBankName ? (
                <Flex className="no-bank-found">
                  <IconAlertTriangleFilled />
                  <div className="nbf-details">
                    <h4>We couldn’t verify your routing number</h4>
                    <p>Please enter your bank's name below</p>
                  </div>
                </Flex>
              ) : null}
            </Flex>

            {current.routingDone && !current?.plaidBankName ? (
              <Flex vertical={true}>
                <InputLabel htmlFor="bank" {...validation.bank}>
                  Enter Your Bank Name
                </InputLabel>
                <FormInput
                  id="bank"
                  size="medium"
                  value={form.bank}
                  onChange={(e) => updateForm("bank", e.target.value)}
                  placeholder="e.g. Bank of America"
                  disabled={saving}
                  {...validation.bank}
                />
              </Flex>
            ) : null}
            {current.routingDone ? (
              <>
                <Flex vertical={true}>
                  <InputLabel htmlFor="account" {...validation.account}>
                    Account Number *
                  </InputLabel>
                  <FormInput
                    id="account"
                    size="medium"
                    value={form.account}
                    onChange={(e) =>
                      updateForm("account", NumbersOnly(e.target.value))
                    }
                    placeholder="e.g. 999938472638..."
                    maxLength={16}
                    disabled={saving}
                    {...validation.account}
                  />
                </Flex>
                <Flex vertical={true}>
                  <InputLabel htmlFor="reAccount" {...validation.reAccount}>
                    Re-enter Account Number *
                  </InputLabel>
                  <FormInput
                    id="reAccount"
                    size="medium"
                    value={form.reAccount}
                    onChange={(e) =>
                      updateForm("reAccount", NumbersOnly(e.target.value))
                    }
                    placeholder="e.g. 999938472638..."
                    maxLength={16}
                    disabled={saving}
                    {...validation.reAccount}
                  />
                  {noMatch ? (
                    <div className="no-match">
                      Account numbers must be the same
                    </div>
                  ) : null}
                </Flex>
                <Flex vertical={true} className="account-type">
                  <InputLabel htmlFor="accountType">Account Type *</InputLabel>
                  <Radio.Group
                    onChange={(e) => updateForm("accountType", e.target.value)}
                    value={form.accountType}
                    disabled={saving}
                  >
                    <Radio value="checking">Checking</Radio>
                    <Radio value="savings">Savings</Radio>
                  </Radio.Group>
                </Flex>
              </>
            ) : null}
          </div>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button
          onClick={createBankAccount}
          type="primary"
          size="large"
          block
          className="green-btn"
          disabled={!isValid}
          loading={saving}
        >
          Save Bank Account
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AccountRouting;
