import React from "react";
import { IconPencil } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import "./AddressCard.scss";

const AddressCard = (props) => {
  const { address, destination, onClick } = props;
  return (
    <div className="address-card">
      <label>Mail {destination === "to" ? "To" : "From"}</label>
      {address.name ? (
        <div className="ac-name">
          <b>{address.name}</b>
        </div>
      ) : (
        <div className="ac-name">
          <i>Name</i>
        </div>
      )}
      <div className="ac-address">
        {address.address || <i>123 Fake St.</i>}
        {address?.address_line_two ? `, ${address.address_line_two}` : ""}
      </div>
      <div className="ac-location">
        {address.city ? `${address.city},` : <i>City,</i>}&nbsp;
        {address.state || <i>State</i>}
        &nbsp;
        {address.zip || <i>00000</i>}
      </div>
      <div className="ac-hover">
        <Button size="small" className="boxed" type="primary" onClick={onClick}>
          <IconPencil /> Update Address
        </Button>
      </div>
    </div>
  );
};

export default AddressCard;
