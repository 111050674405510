import { Text } from "@centrate-io/barn";
import {
  IconScale,
  IconCalendar,
  IconSubtask,
  IconMap,
} from "@tabler/icons-react";
import { mailStatusMap } from "_constants/twilio";
import moment from "moment";

export const getColumns = () => {
  return [
    {
      title: <div className="column-name">Description</div>,
      dataIndex: "description",
      key: "description",
      sorter: true,
      render: (description) => <Text>{description || "---"}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconMap />
          To
        </div>
      ),
      dataIndex: "to",
      key: "to",
      sorter: false,
      render: (to, mailData) => {
        const data = mailData?.to || {};
        const noAddress =
          !data.address && !data.city && !data.state && !data.zip;
        return noAddress ? (
          <div>
            <Text>
              <i>n/a</i>
            </Text>
          </div>
        ) : (
          <div>
            <Text>
              {data.address} {data.address_line_two}
            </Text>
            <Text>
              {data.city}, {data.state} {data.zip}
            </Text>
          </div>
        );
      },
    },
    {
      title: (
        <div className="column-name">
          <IconMap />
          From
        </div>
      ),
      dataIndex: "from",
      key: "from",
      sorter: false,
      render: (from, mailData) => {
        const data = mailData?.from || {};
        const noAddress =
          !data.address && !data.city && !data.state && !data.zip;
        return noAddress ? (
          <div>
            <Text>
              <i>n/a</i>
            </Text>
          </div>
        ) : (
          <div>
            <Text>
              {data.address} {data.address_line_two}
            </Text>
            <Text>
              {data.city}, {data.state} {data.zip}
            </Text>
          </div>
        );
      },
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status) => (
        <Text className="call-task">{mailStatusMap[status]}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconSubtask />
          Task
        </div>
      ),
      dataIndex: "task",
      key: "task",
      sorter: true,
      render: (task, data) => (
        <Text className="call-task">{data?.task?.title || "----"}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Created
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 220,
      render: (createdAt) => (
        <Text>
          {createdAt ? (
            moment(parseInt(createdAt)).format("MMM D, YYYY - h:mm A")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
  ];
};
