import React, { useState, useEffect } from "react";
import { Table, Empty } from "_styleguide";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { adminMail } from "App/Admin/_graphql/queries/mail";
import { getColumns } from "./_data/Columns";
import { IconPuzzle } from "@tabler/icons-react";
import { useIntegrations } from "_contexts/useIntegrations";
import { Button } from "@centrate-io/barn";
import { useBar } from "_contexts/useBar";
import "./Mail.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "first_attempt",
  sortOrder: "ascend",
};

function Mail(props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { setupMail, customer } = useBar();
  const { integrations } = useIntegrations();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [page, setPage] = useState(1);

  // Load Payments
  const fetchMail = async (params = defaultParams) => {
    const queryData = await client.query({
      variables: {
        ...params,
        id: props.applicationId,
      },
      query: adminMail,
    });
    const pagination = { ...state.pagination };
    pagination.total = queryData?.data?.adminMail?.pageTotal;
    pagination.totalAll = queryData?.data?.adminMail?.total;
    setPage(params?.page || 1);
    setState({
      loading: false,
      data: queryData?.data?.adminMail?.data || [],
      pagination: pagination,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchMail({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  useEffect(() => {
    fetchMail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;
  const columns = getColumns();

  const makeMail = () => {
    setupMail(customer, null, true);
  };

  if (state?.data?.length <= 0 && !integrations.mail) {
    return (
      <Empty
        title="No Mail Integration"
        description="Setup your Postgrid integration send physical letters on behalf of or to the customer."
        action="Setup Integration"
        actionIcon={<IconPuzzle />}
        onClick={() => navigate("/settings/integrations")}
      />
    );
  }

  return (
    <div className="admin-application-mail">
      <div className="manage-search">
        <div className="ms-actions"></div>
        <div className="ms-filters">
          <Button type="primary" size="small" onClick={makeMail}>
            + New Mail
          </Button>
        </div>
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 276px - 40px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading ? "Loading mail..." : "No mail found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default Mail;
