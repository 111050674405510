import { useEffect, useRef, useState } from "react";
import "./IFrameViewer.scss";

const IFrameViewer = ({ instructions, variables }) => {
  const iframeRef = useRef(null);
  const containerRef = useRef(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [iframeSize, setIframeSize] = useState({ width: 1024, height: 768 });

  const generateContent = () => {
    let content = instructions;
    if (variables?.length > 0) {
      variables.forEach((v) => {
        const variableToReplace = `{{${v.variable}}}`;
        const replaceWith = v.value;
        if (replaceWith) {
          const regex = new RegExp(variableToReplace, "g");
          content = content.replace(regex, replaceWith);
        }
      });
    }
    return content;
  };

  const adjustIframeScale = () => {
    if (!containerRef.current || !iframeRef.current) return;

    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;

    if (!doc || !doc.body) return;

    // Get the actual width and height of the iframe's content
    const contentWidth = doc.body.scrollWidth;
    const contentHeight = doc.body.scrollHeight;

    // Get the available space in the parent container
    const parentWidth = containerRef.current.clientWidth;
    setIframeSize({ width: contentWidth, height: contentHeight });

    // Calculate the scale factor to fit inside the parent container
    const scaleW = parentWidth / contentWidth;
    const scale = scaleW; // Maintain aspect ratio

    setScaleFactor(scale);
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    // Wait for iframe to load, then adjust scale
    iframe.addEventListener("load", adjustIframeScale);
    window.addEventListener("resize", adjustIframeScale);

    return () => {
      iframe.removeEventListener("load", adjustIframeScale);
      window.removeEventListener("resize", adjustIframeScale);
    };
  }, []);

  return (
    <div ref={containerRef} className="mail-iframe-viewer">
      <iframe
        ref={iframeRef}
        title="Email Preview"
        width={iframeSize.width}
        height={iframeSize.height}
        style={{
          transform: `scale(${scaleFactor})`,
          transformOrigin: "top left",
          border: 0,
        }}
        srcDoc={generateContent()}
        sandbox="allow-same-origin"
      />
    </div>
  );
};

export default IFrameViewer;
