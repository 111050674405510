import React, { useState, useEffect } from "react";
import NoWorkflows from "./_components/NoWorkflows/NoWorkflows";
import CreateWorkflow from "./_modals/CreateWorkflow/CreateWorkflow";
import { getWorkflows } from "App/Admin/_graphql/queries/workflow";
import { deleteWorkflow } from "App/Admin/_graphql/mutations/workflow";
import { useApolloClient } from "@apollo/client";
import { Loading, Table, Search } from "_styleguide";
import { getColumns } from "./_data/Columns";
import { IconSubtask, IconPlus } from "@tabler/icons-react";
import { Button, Modal, message } from "@centrate-io/barn";
import { useNavigate } from "react-router-dom";
import { getErrors } from "_helpers/api";
import "./Workflows.scss";

function Workflows(props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [createWorkflowModal, setCreateWorkflowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [workflows, setWorkflows] = useState([]);
  const [search, setSearch] = useState();
  const showNone = workflows?.length <= 0;

  // Load Payments
  const fetchWorkflows = async () => {
    const queryData = await client.query({
      query: getWorkflows,
    });
    setLoading(false);
    setWorkflows(queryData?.data?.getWorkflows || []);
  };
  const removeWorkflow = async (workflow_id) => {
    Modal.confirm({
      title: "Are you sure you want remove this workflow?",
      okText: "Remove Workflow",
      content:
        "This action cannot be reversed and will remove the workflow and it can no longer be applied to future customers.  This will not affect any templates or tasks.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: { workflow_id },
            mutation: deleteWorkflow,
          });
          message.success(`Workflow has been removed`);
          fetchWorkflows();
        } catch (err) {
          message.error(getErrors(err) || "Error removing workflow, try again");
        }
      },
    });
  };

  useEffect(() => {
    props.setCrumbs({
      title: "Manage Workflows",
      icon: <IconSubtask />,
      description:
        "Manual or automated messaging, for customer correspondence...",
    });
    props?.setCurrentCount(0);
    fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToWorkflow = (id) => {
    navigate(`/settings/workflows/${id}`);
  };

  const columns = getColumns(goToWorkflow, removeWorkflow);

  const filteredWorkflows = workflows.filter((w) => {
    if (search?.length <= 0 || !search) return true;
    return w.name?.toLowerCase().includes(search?.toLowerCase());
  });

  return loading ? (
    <Loading />
  ) : (
    <>
      {createWorkflowModal ? (
        <CreateWorkflow
          reload={fetchWorkflows}
          removeModal={() => setCreateWorkflowModal(false)}
          goToWorkflow={goToWorkflow}
        />
      ) : null}
      {showNone ? (
        <NoWorkflows
          {...props}
          showModal={() => setCreateWorkflowModal(true)}
        />
      ) : (
        <div className="settings-workflows">
          <div className="settings-search">
            <div className="ms-filters">
              <Search
                placeholder={`Search ${workflows?.length || ""} workflows...`}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ width: 304 }}
              />
            </div>
            <div className="ms-actions">
              <Button
                type="primary"
                className="boxed"
                onClick={() => setCreateWorkflowModal(true)}
              >
                <IconPlus /> Create Workflow
              </Button>
            </div>
          </div>
          <div className="workflows">
            <Table
              columns={columns}
              pagination={{ pageSize: 10, showSizeChanger: false }}
              rowKey={(record) => record.id}
              dataSource={filteredWorkflows}
              showSorterTooltip={false}
              scroll={{
                y: "calc(80vh - 231px)",
                x: "max-content",
              }}
              locale={{
                emptyText: "No workflows found",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Workflows;
