import { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./PDFViewer.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PDFViewer = ({ pdfData }) => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const containerRef = useRef(null);

  const adjustScale = (pageWidth) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const newScale = containerWidth / pageWidth;
      setScale(newScale);
    }
  };

  // Decode the Base64 string and convert it to a Blob
  useEffect(() => {
    if (pdfData) {
      const binary = atob(pdfData); // Decode Base64
      const bytes = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: "application/pdf" });
      setPdfBlob(blob);
    }
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageLoadSuccess = (page) => {
    adjustScale(page.originalWidth); // Adjust scale based on page width
  };

  return (
    <div ref={containerRef} className="mail-pdf-viewer">
      {pdfBlob ? (
        <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
          {numPages &&
            Array.from({ length: numPages }, (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scale}
                onLoadSuccess={onPageLoadSuccess}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                style={{ marginBottom: "12px" }}
              />
            ))}
        </Document>
      ) : (
        <p>No PDF data available</p>
      )}
    </div>
  );
};

export default PDFViewer;
