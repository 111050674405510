import { useState, useEffect, useRef } from "react";
import { CallsClient } from "_graphql/clients/CallsClient";
import { Button, message } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { TextArea, Select, TaskBadge, Tag } from "_styleguide";
import { getTextMessages } from "_graphql/queries/textmessage";
import { createTextMessage } from "_graphql/mutations/textmessage";
import {
  IconX,
  IconArrowsDiagonalMinimize2,
  IconCheck,
} from "@tabler/icons-react";
import { useBar } from "_contexts/useBar";
import { useIntegrations } from "_contexts/useIntegrations";
import { fetchTemplates, replaceData } from "../../_helpers/templates";
import CompleteTask from "App/Admin/Application/_components/Tasks/_components/CompleteTask/CompleteTask";
import { useApolloClient } from "@apollo/client";
import Search from "../Search/Search";
import Missing from "../Missing/Missing";
import io from "socket.io-client";
import { callsApi } from "_helpers/api";
import "./Texting.scss";

function Texting(props) {
  const callsClient = CallsClient;
  const client = useApolloClient();
  const { activeData, closeCall, toggleMinimize, minimizing } = useBar();
  const { integrations } = useIntegrations();
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [texts, setTexts] = useState([]);
  const [text, setText] = useState(null);
  const [data, setData] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [template, setTemplate] = useState(
    activeData?.task?.template_id || undefined,
  );
  const [completeModal, setCompleteModal] = useState(false);

  const endOfListRef = useRef(null);

  const minimize = activeData?.minimize;
  const APPLICATION_ID = activeData?.customer?.id;
  const TASK_ID = activeData?.task?.id;

  // Load Text Messages
  const fetchMessages = async () => {
    const queryData = await callsClient.query({
      variables: { application_id: APPLICATION_ID },
      query: getTextMessages,
    });
    setTexts(queryData?.data?.getTextMessages?.reverse() || []);
    setLoading(false);
    setTimeout(() => {
      if (endOfListRef.current) {
        endOfListRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const sendText = async () => {
    setSending(true);
    try {
      await callsClient.mutate({
        variables: {
          application_id: APPLICATION_ID,
          task_id: TASK_ID || null,
          content: text,
        },
        mutation: createTextMessage,
      });
      message.success("Message sent");
      fetchMessages();
      setText(null);
      setTemplate(null);
      setSending(false);
      if (activeData?.task?.id) {
        setCompleted(true);
      }
    } catch (err) {
      message.error(getErrors(err) || "Error sending message, try again");
      setSending(false);
    }
  };

  useEffect(() => {
    if (activeData?.customer?.id) fetchMessages();
    const fetchData = async () => {
      try {
        const data = await fetchTemplates(
          client,
          "text",
          activeData.customer?.id,
        );
        setData(data); // Handle the fetched data
        if (activeData?.task?.template_id) {
          const templateData = data?.templates?.find(
            (t) => t.id === activeData?.task?.template_id,
          );
          if (!templateData?.instructions) {
            setTemplate(undefined);
            return;
          }
          const newInstructions = replaceData(
            templateData,
            data.customer,
            props.user,
          );
          setText(newInstructions);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    if (activeData.customer?.id) fetchData();
  }, []);

  // Setup IO
  useEffect(() => {
    if (APPLICATION_ID) {
      // Initialize the socket inside the component
      const newSocket = io(callsApi(), {
        withCredentials: true,
      }); // Replace with your server's URL

      // Listen for call status updates from the server
      newSocket.on(`incomingText-${APPLICATION_ID}`, fetchMessages);

      // Clean up the socket connection when the component unmounts
      return () => {
        newSocket.off(`incomingText-${APPLICATION_ID}`);
        newSocket.disconnect();
      };
    }
  }, []);

  const changeTemplate = (templateId) => {
    const templateData = data?.templates?.find((t) => t.id === templateId);
    setTemplate(templateId);
    if (!templateData) {
      setText(null);
      return;
    }
    const newInstructions = replaceData(
      templateData,
      data.customer,
      props.user,
    );
    setText(newInstructions || "");
  };

  const templateOptions = data?.templates || [];

  const reload = () => {
    props.fetchTaskCount();
    if (activeData?.task?.reload) activeData?.task?.reload();
    closeCall();
  };

  if (!integrations?.text) {
    return <Missing type="text" />;
  }

  return !activeData?.customer ? (
    <Search type="text" />
  ) : (
    <div
      className={`pp-texting ${minimize ? "minimize" : ""} ${minimizing ? "minimizing" : ""}`}
    >
      {completeModal ? (
        <CompleteTask
          taskId={activeData?.task?.id}
          reload={reload}
          removeModal={() => setCompleteModal(false)}
        />
      ) : null}
      <div className="bar-top">
        {activeData?.customer ? (
          <h3>
            {activeData?.customer?.first_name} {activeData?.customer?.last_name}
          </h3>
        ) : null}
        {activeData?.task ? (
          <Tag size="small" type="task">
            {activeData?.task?.title}
          </Tag>
        ) : null}
      </div>
      <div className={`pp-templates ${props.visible ? "visible" : ""}`}>
        <Select
          value={template}
          onChange={changeTemplate}
          size="medium"
          popupClassName="pp-select-dropdown ppsd-task"
          placeholder="---"
        >
          <Select.Option value={null}>---</Select.Option>
          {templateOptions.map((t) => {
            return (
              <Select.Option key={t.id} value={t.id}>
                <TaskBadge type="text" />
                {t.title}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="chat-window">
        {texts?.map((text, i) => {
          let className = "message";
          let from = text.user?.name;
          if (text.outgoing) {
            className += " right";
          } else {
            from =
              text.application?.first_name + " " + text.application?.last_name;
            className += " left";
          }
          return (
            <div key={text.id} className={className}>
              <div>{from}</div>
              {text.content || " "}
            </div>
          );
        })}
        {texts?.length <= 0 && !loading ? (
          <div className="no-text">No text history yet</div>
        ) : null}
        <div ref={endOfListRef}></div>
      </div>

      <div className="text-compose">
        <TextArea
          disabled={sending || completed}
          value={completed ? "" : text}
          onChange={(e) => setText(e.target.value)}
          size="large"
          autoSize={true}
          placeholder="Message..."
        />
        <Button
          className="send-btn"
          disabled={!text || text?.length < 1 || completed}
          loading={sending}
          type="primary"
          size="small"
          onClick={() => sendText()}
        >
          Send
        </Button>
        {completed ? (
          <Button
            className="green-btn boxed"
            type="primary"
            size="small"
            onClick={() => setCompleteModal(true)}
          >
            <IconCheck /> Complete Task
          </Button>
        ) : null}
      </div>
      <div className="bar-minimize" onClick={toggleMinimize}>
        <IconArrowsDiagonalMinimize2 />
      </div>
      <div className="bar-close" onClick={closeCall}>
        <IconX />
      </div>
    </div>
  );
}

export default Texting;
