import React, { useState, useCallback } from "react";
import { AutoComplete, Input } from "antd";
import { gql, useLazyQuery } from "@apollo/client";
import { debounce } from "_assets/js/helpers";
import "./SearchAddressBook.scss";

const SEARCH_ADDRESS_BOOK = gql`
  query searchAddressBook($query: String!) {
    searchAddressBook(query: $query) {
      id
      name
      address
      address_line_two
      city
      state
      zip
    }
  }
`;

const SearchAddressBook = (props) => {
  const [options, setOptions] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [searchAddressBook, { data }] = useLazyQuery(SEARCH_ADDRESS_BOOK);

  // Debounced search function with 300ms delay
  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value) {
        searchAddressBook({ variables: { query: value } });
      }
    }, 510),
    [searchAddressBook],
  );

  const handleSearch = (value) => {
    debouncedSearch(value);
  };

  const onSelect = (address_id) => {
    const addressData = addresses?.find((c) => c.id === address_id);
    props.onSelect(addressData);
  };

  // Update the options when the data is received
  React.useEffect(() => {
    if (data && data.searchAddressBook) {
      const addressOptions = data.searchAddressBook.map((address) => ({
        value: address.id,
        label: (
          <div className="sab-option">
            {address.name}
            <span>{address.address}</span>
          </div>
        ),
      }));
      setOptions(addressOptions);
      setAddresses(data.searchAddressBook);
    }
  }, [data]);

  const selectedValue = props.value ? props.value?.name : undefined;

  return (
    <AutoComplete
      allowClear={true}
      options={options}
      onSearch={handleSearch}
      onSelect={onSelect}
      onClear={() => props.onSelect(undefined)}
      value={selectedValue}
      placeholder="Search Address Book..."
      popupClassName="pp-select-dropdown ppsd-task sab-drop"
      className="pp-select sab-search"
    >
      <Input.Search />
    </AutoComplete>
  );
};

export default SearchAddressBook;
