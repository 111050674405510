import React, { useState, useEffect } from "react";
import { Button, message, Modal } from "@centrate-io/barn";
import {
  IconX,
  IconArrowsDiagonalMinimize2,
  IconCheck,
  IconChevronLeft,
} from "@tabler/icons-react";
import { Select, Tag, InputLabel, Loading } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { useBar } from "_contexts/useBar";
import { useIntegrations } from "_contexts/useIntegrations";
import Search from "../Search/Search";
import Missing from "../Missing/Missing";
import { getCompany } from "_graphql/queries/company";
import CompleteTask from "App/Admin/Application/_components/Tasks/_components/CompleteTask/CompleteTask";
import {
  getPostGridMailTemplates,
  getPostGridPDF,
} from "App/Admin/_graphql/queries/postgrid";
import { getErrors } from "_helpers/api";
import { gatherInitial, matchVariables } from "./_helpers";
import EditAddressModal from "./_modals/EditAddressModal/EditAddressModal";
import EditVariables from "./_modals/EditVariables/EditVariables";
import AddressCard from "./_components/AddressCard/AddressCard";
import { validateAddressBook } from "_helpers/validateForm";
import {
  savePostGridDraft,
  cancelPostGridDraft,
  sendPostGridDraft,
} from "App/Admin/_graphql/mutations/postgrid";
import PDFViewer from "./_components/PDFViewer/PDFViewer";
import IFrameViewer from "./_components/IFrameViewer/IFrameViewer";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./Mail.scss";

function Mail(props) {
  const client = useApolloClient();
  const { integrations } = useIntegrations();
  const { activeData, closeCall, toggleMinimize, minimizing } = useBar();
  const [editModal, setEditModal] = useState(false);
  const [variablesModal, setVariablesModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [addressBook, setAddressBook] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [backing, setBacking] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [templates, setTemplates] = useState();
  const [variables, setVariables] = useState([]);
  const [letter, setLetter] = useState();
  const [PDF, setPDF] = useState(null);

  // Mail Info
  const initialData = gatherInitial();
  const [template, setTemplate] = useState();
  const [to, setTo] = useState(initialData.to);
  const [from, setFrom] = useState(initialData.from);

  // Other Variables
  const minimize = activeData?.minimize;
  const APPLICATION_ID = activeData?.customer?.id;

  // Get PostGrid Templates
  const fetchTemplates = async () => {
    try {
      const queryData = await client.query({
        variables: {
          application_id: APPLICATION_ID,
        },
        query: getPostGridMailTemplates,
      });
      const templateList = queryData?.data?.getPostGridTemplates || [];
      setTemplates(templateList);
      if (activeData?.task?.template_id && templateList?.length > 0) {
        const templateData = templateList.find(
          (t) => t.id === activeData?.task?.template_id,
        );
        selectTemplate(activeData?.task?.template_id, templateData);
      }
    } catch (err) {
      message.error(
        getErrors(err) || "Error getting PostGrid templates, try again",
      );
    }
  };

  // Load Settings
  const fetchCompany = async () => {
    const companyResponse = await client.query({
      query: getCompany,
    });
    const filledFormData = gatherInitial(companyResponse?.data?.getCompany);
    setFrom(filledFormData.from);
  };

  const fetchPDF = async (letter_id) => {
    setPDF(null);
    try {
      const queryData = await client.query({
        variables: { letter_id },
        query: getPostGridPDF,
      });
      setPDF(queryData?.data?.getPostGridPDF || "FAILED");
    } catch (err) {
      setPDF("FAILED");
      message.error(getErrors(err) || "Error getting PDF preview, try again");
    }
  };

  const selectTemplate = (template_id, alreadyHaveData) => {
    const templateData = alreadyHaveData
      ? alreadyHaveData
      : templates?.find((t) => t.id === template_id);
    if (!templateData) return;
    const variableData = matchVariables(templateData, to, from, props.user);
    setVariables(variableData);
    setTemplate(template_id);
  };

  const goBack = async (closeAfter) => {
    if (letter) {
      try {
        setBacking(true);
        await client.mutate({
          variables: {
            letter_id: letter,
          },
          mutation: cancelPostGridDraft,
        });
        if (closeAfter) {
          closeCall();
          return;
        }
        setBacking(false);
      } catch (err) {
        setBacking(false);
      }
    }
    setLetter(null);
    setPDF(null);
  };

  // Remove Bank Account
  const cancelSend = async (closeAfter) => {
    Modal.confirm({
      title: "Undo Send?",
      okText: "Undo Send",
      content: (
        <p>
          This will stop the letter from being sent, allowing you to make an
          updates changes or cancel it completely and exit.
        </p>
      ),
      onOk: async () => {
        await goBack(closeAfter);
      },
    });
  };

  const sendLetter = async () => {
    try {
      setSaving(true);
      const sendData = await client.mutate({
        variables: {
          letter_id: letter,
          application_id: APPLICATION_ID || null,
        },
        mutation: sendPostGridDraft,
      });
      const wasSent = sendData?.data?.sendPostGridDraft;
      if (!wasSent) throw new Error();
      message.success("Mail is printing & sending via PostGrid");
      if (activeData?.task?.id) {
        setCompleted(true);
      } else {
        closeCall();
      }
    } catch (err) {
      message.error(getErrors(err) || "Error creating preview, try again");
      setSaving(false);
    }
  };

  // Create Draft
  const createDraft = async (letter_id) => {
    try {
      setSaving(true);
      const templateData = templates?.find((t) => t.id === template);
      const draftData = await client.mutate({
        variables: {
          to,
          from,
          variables: variables || [],
          template_name: templateData?.title || null,
          template_id: template,
          customer_id: APPLICATION_ID,
        },
        mutation: savePostGridDraft,
      });
      const newLetterId = draftData?.data?.savePostGridDraft?.letter_id;
      if (!newLetterId) throw new Error();
      setLetter(newLetterId);
      setSaving(false);
      await fetchPDF(newLetterId);
    } catch (err) {
      message.error(getErrors(err) || "Error creating preview, try again");
      setSaving(false);
    }
  };

  const reload = () => {
    props.fetchTaskCount();
    if (activeData?.task?.reload) activeData?.task?.reload();
    closeCall();
  };

  useEffect(() => {
    fetchTemplates();
    fetchCompany();
  }, []);

  const validateTo = validateAddressBook(to);
  const validateFrom = validateAddressBook(from);

  const draftMode = letter ? true : false;
  const selectedTemplate = templates?.find((t) => t.id === template);

  const assignedVariables = variables?.filter((v) => v.value)?.length;
  const variablesValid = variables?.length === assignedVariables;

  const previewMode =
    !draftMode && selectedTemplate?.instructions ? true : false;

  if (templates === undefined) {
    return (
      <div
        className={`bar-mail ${minimize ? "minimize" : ""} ${minimizing ? "minimizing" : ""}`}
      >
        <div className="bar-top">
          {activeData?.customer ? (
            <h3>
              {activeData?.customer?.first_name}{" "}
              {activeData?.customer?.last_name}
            </h3>
          ) : null}
          {activeData?.task ? (
            <Tag size="small" type="task">
              {activeData?.task?.title}
            </Tag>
          ) : null}
        </div>
        <div className="bar-minimize" onClick={toggleMinimize}>
          <IconArrowsDiagonalMinimize2 />
        </div>
        <div
          className="bar-close"
          onClick={() =>
            draftMode && !completed ? cancelSend(true) : closeCall()
          }
        >
          <IconX />
        </div>
        <Loading />
      </div>
    );
  }
  if (!integrations?.mail) return <Missing type="mail" />;

  return !activeData?.customer ? (
    <Search type="mail" />
  ) : (
    <div
      className={`bar-mail ${minimize ? "minimize" : ""} ${minimizing ? "minimizing" : ""} ${draftMode || completed ? "draft-mode" : ""} ${previewMode ? "preview-mode" : ""}`}
    >
      {completeModal ? (
        <CompleteTask
          taskId={activeData?.task?.id}
          reload={reload}
          removeModal={() => setCompleteModal(false)}
        />
      ) : null}
      {editModal ? (
        <EditAddressModal
          destination={editModal}
          address={editModal === "to" ? to : from}
          addressBook={addressBook}
          setAddressBook={setAddressBook}
          setTo={setTo}
          setFrom={setFrom}
          removeModal={() => setEditModal(false)}
        />
      ) : null}
      {variablesModal ? (
        <EditVariables
          variables={variables}
          saveVariables={setVariables}
          removeModal={() => setVariablesModal(false)}
        />
      ) : null}
      <div className="bar-top">
        {activeData?.customer ? (
          <h3>
            {activeData?.customer?.first_name} {activeData?.customer?.last_name}
          </h3>
        ) : null}
        {activeData?.task ? (
          <Tag size="small" type="task">
            {activeData?.task?.title}
          </Tag>
        ) : null}
      </div>
      <div className="mail-window">
        <div className="mail-addresses">
          <AddressCard
            onClick={() => setEditModal("to")}
            address={to}
            destination="to"
          />
          <AddressCard
            onClick={() => setEditModal("from")}
            address={from}
            destination="from"
          />
        </div>

        {draftMode ? (
          <>
            {PDF ? (
              <div className="mail-preview">
                <div className="pdf-box">
                  {PDF === "FAILED" ? (
                    <div className="pdf-failed">
                      <p>
                        PDF is still preparing... This could take up to a minute
                        or two.
                      </p>
                      <Button
                        className="boxed"
                        type="blanco"
                        size="small"
                        onClick={() => fetchPDF(letter)}
                      >
                        Try Again
                      </Button>
                    </div>
                  ) : (
                    <PDFViewer pdfData={PDF} />
                  )}
                </div>
              </div>
            ) : (
              <div className="mail-preview">
                <div className="pdf-box">
                  <Spin indicator={<LoadingOutlined spin />} size="large" />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="mail-template">
              <InputLabel htmlFor="template">Choose Template</InputLabel>
              <Select
                id="template"
                value={template}
                style={{
                  width: "100%",
                }}
                size="medium"
                placeholder="Select Template..."
                onChange={(template_id) => selectTemplate(template_id)}
                options={
                  templates?.map((t) => ({ value: t.id, label: t.title })) || []
                }
              />
            </div>
            {selectedTemplate ? (
              <div className="mail-html-preview">
                <div className="pdf-box">
                  <IFrameViewer
                    instructions={selectedTemplate.instructions}
                    variables={variables}
                  />
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>

      <div className="mail-actions">
        {draftMode && !completed ? (
          <>
            <Button
              className="boxed"
              type="secondary"
              block
              loading={backing}
              disabled={saving || !PDF}
              style={{ justifyContent: "center" }}
              onClick={() => cancelSend(false)}
            >
              <IconChevronLeft />
              Undo Send & Edit
            </Button>
            <Button
              block
              className="boxed"
              type="primary"
              onClick={sendLetter}
              loading={saving}
              disabled={
                !validateTo.allValid ||
                !validateFrom.allValid ||
                !template ||
                backing ||
                !PDF
              }
              style={{ justifyContent: "center" }}
            >
              Confirm Send
            </Button>
          </>
        ) : !completed ? (
          <>
            <Button
              className="boxed"
              type="blanco"
              onClick={() => setVariablesModal(true)}
              block
              disabled={variables?.length <= 0}
              style={{ justifyContent: "center" }}
            >
              Variables
              {variables?.length > 0
                ? ` ${assignedVariables}/${variables?.length}`
                : ""}
            </Button>
            <Button
              block
              className="boxed"
              type="primary"
              onClick={createDraft}
              loading={saving}
              disabled={
                !validateTo.allValid ||
                !validateFrom.allValid ||
                !template ||
                !variablesValid
              }
              style={{ justifyContent: "center" }}
            >
              Review & Send
            </Button>
          </>
        ) : null}
        {completed ? (
          <div className="complete-mail-task">
            <Button
              className="green-btn boxed"
              type="primary"
              onClick={() => setCompleteModal(true)}
            >
              <IconCheck /> Complete Task
            </Button>
          </div>
        ) : null}
      </div>
      <div className="bar-minimize" onClick={toggleMinimize}>
        <IconArrowsDiagonalMinimize2 />
      </div>
      <div
        className="bar-close"
        onClick={() =>
          draftMode && !completed ? cancelSend(true) : closeCall()
        }
      >
        <IconX />
      </div>
    </div>
  );
}

export default Mail;
