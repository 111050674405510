import { Select } from "_styleguide";
import { TAG_MAP, DATES_TAG_MAP } from "_constants/mapping";
import "./TemplateMapping.scss";

function TemplateMapping(props) {
  const { form, updateForm } = props;

  const changeRow = (index, value) => {
    let newMapping = [...form.variables];
    newMapping[index].mapping = value;
    updateForm("variables", newMapping);
  };

  const postGridVariables = [
    "to.companyName",
    "to.addressLine1",
    "to.addressLine2",
    "to.city",
    "to.provinceOrState",
    "to.postalOrZip",
    "from.companyName",
    "from.addressLine1",
    "from.addressLine2",
    "from.city",
    "from.provinceOrState",
    "from.postalOrZip",
  ];

  const MAIL_TAG_MAP = { ...TAG_MAP, ...DATES_TAG_MAP };

  return (
    <div className="ct-mapping">
      <div className="wb-headers">
        <div>Variable Name</div>
        <div className="ct-select">Replace with</div>
        <div>Example</div>
      </div>
      <div className="wb-work">
        {form.variables?.map((m, i) => {
          const isPostGrid = postGridVariables?.includes(m.variable);
          return (
            <div key={`wb-map-${i}`} className="wb-map">
              <div>
                <b>{`{{${m.variable}}}`}</b>
              </div>
              <div className="ct-select">
                <Select
                  // showSearch
                  // allowClear={true}
                  disabled={isPostGrid}
                  value={isPostGrid ? "PostGrid Variable" : m.mapping}
                  onChange={(val) =>
                    changeRow(i, val === "IGNORE" ? undefined : val)
                  }
                  size="medium"
                  placeholder="--- Ignore Field ---"
                  popupClassName="pp-select-dropdown ppsd-task"
                >
                  <Select.Option value="IGNORE">
                    --- Ignore Field ---
                  </Select.Option>
                  {Object.keys(MAIL_TAG_MAP)?.map((u, j) => {
                    const tagData = MAIL_TAG_MAP[u];
                    return (
                      <Select.Option key={`ct-${u}`} value={u}>
                        {tagData.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div>
                <i>{MAIL_TAG_MAP[m.mapping]?.example || "n/a"}</i>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TemplateMapping;
