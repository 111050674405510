import { extractVariables } from "_assets/js/helpers";
import moment from "moment";

const defaultAdddress = {
  name: undefined,
  address: undefined,
  address_line_two: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
};

const pgMap = {
  name: "companyName",
  address: "addressLine1",
  address_line_two: "addressLine2",
  city: "city",
  state: "provinceOrState",
  zip: "postalOrZip",
};

export const gatherInitial = (companyData) => {
  let to = { ...defaultAdddress };
  let from = { ...defaultAdddress };

  if (companyData) {
    from.name = companyData.address_name;
    from.address = companyData.address;
    from.address_line_two = companyData.address_line_two;
    from.city = companyData.city;
    from.state = companyData.state;
    from.zip = companyData.zip;
  }

  return { to, from };
};

const getDateOfBirth = (dobVal) => {
  let nextValue = undefined;
  if (dobVal) {
    if (dobVal?.length <= 13) {
      nextValue = moment(parseInt(dobVal)).utc().format("MM/DD/YYYY");
    } else {
      nextValue = moment(dobVal).utc().format("MM/DD/YYYY");
    }
    return nextValue;
  } else {
    return undefined;
  }
};

const getDates = (dateType) => {
  if (dateType === "date.us") {
    return moment().format("MM/DD/YYYY");
  } else if (dateType === "date.full") {
    return moment().format("MMMM D, YYYY");
  }
  return moment().format("MMMM D, YYYY");
};

const getValue = (mapping, customer, user, company, to, from) => {
  let hasValue = undefined;
  if (mapping?.includes("date.")) {
    hasValue = getDates(mapping);
  }
  Object.keys(customer || {}).forEach((cKey) => {
    if (mapping === "customer.dob") {
      hasValue = getDateOfBirth(customer[cKey]);
    } else if (mapping === `customer.${cKey}`) hasValue = customer[cKey];
  });
  Object.keys(user).forEach((uKey) => {
    if (mapping === `employee.${uKey}`) hasValue = user[uKey];
  });
  Object.keys(company).forEach((cKey) => {
    if (mapping === `company.${cKey}`) hasValue = user[cKey];
  });
  Object.keys(to).forEach((cKey) => {
    if (mapping === `to.${pgMap[cKey]}`) hasValue = to[cKey];
  });
  Object.keys(from).forEach((cKey) => {
    if (mapping === `from.${pgMap[cKey]}`) hasValue = from[cKey];
  });
  return hasValue || undefined;
};

export const matchVariables = (template, to, from, user) => {
  const variableData = extractVariables(template.instructions || "");
  const mappings = template.variables;
  const finalVariables = [];
  variableData?.forEach((v) => {
    const foundMap = mappings?.find((m) => m.variable === v);
    finalVariables.push({
      variable: v,
      value: getValue(
        foundMap?.mapping || v,
        template?.customer,
        user,
        user.company,
        to,
        from,
      ),
    });
  });
  return finalVariables;
};
