import { Text, Button } from "@centrate-io/barn";
import { TaskBadge } from "_styleguide";
import { IconUser } from "@tabler/icons-react";
import { stripHTML } from "_assets/js/helpers";
import moment from "moment";

export const getColumns = (readOnly, showNewTask, editTemplate) => {
  return [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      render: (type) => (
        <div className="task-badge-type">
          <TaskBadge full="true" type={type} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      render: (title) => (
        <Text>
          <b>{title}</b>
        </Text>
      ),
    },
    {
      title: "Preview",
      dataIndex: "instructions",
      key: "instructions",
      render: (instructions) => {
        if (instructions?.length > 43) {
          return <Text>{stripHTML(instructions || "")?.slice(0, 40)}...</Text>;
        } else if (instructions?.length > 0) {
          return <Text>{stripHTML(instructions || "")}</Text>;
        } else {
          return (
            <Text>
              <i>n/a</i>
            </Text>
          );
        }
      },
    },
    // {
    //   title: "Variables",
    //   dataIndex: "variables",
    //   key: "variables",
    //   render: (variables) => <Text>{variables?.length || 0}</Text>,
    // },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) => (
        <Text>{moment(updatedAt * 1).format("MMM D, YYYY")}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconUser />
          Updated By
        </div>
      ),
      dataIndex: "user",
      key: "user",
      width: 280,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{data?.user?.name}</b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.company?.name}</i>
          </Text>
        </div>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      width: 100,
      align: "center",
      fixed: "right",
      render: (address, data) => (
        <div className="table-actions">
          <Button
            type="blanco"
            size="small"
            className="boxed"
            onClick={() => editTemplate(data)}
          >
            Manage Template
          </Button>
        </div>
      ),
    },
  ];
};
