import {
  validateEmail,
  validateString,
  validateAccountNumber,
  validateRoutingNumber,
} from "_assets/js/helpers";

const hasStarted = (field) => {
  if (field !== undefined && field !== null) {
    return 1;
  }
  return 0;
};

export const validateInviteCompany = (form) => {
  const isValid = {
    company: {
      started: hasStarted(form.company),
      valid: validateString(form.company) ? 1 : 0,
    },
    email: {
      started: hasStarted(form.email),
      valid: validateEmail(form.email || "") ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateInviteUser = (form) => {
  const isValid = {
    email: {
      started: hasStarted(form.email),
      valid: validateEmail(form.email || "") ? 1 : 0,
    },
    role: {
      started: hasStarted(form.role),
      valid: validateString(form.role) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateTemplate = (form, postgrid) => {
  const validateVariables = (variables) => {
    let vvalid = true;
    if (postgrid) return true;
    variables?.forEach((v) => {
      if (!v.mapping) vvalid = false;
    });
    return vvalid;
  };

  const isValid = {
    title: {
      started: hasStarted(form.title),
      valid: validateString(form.title) ? 1 : 0,
    },
    type: {
      started: hasStarted(form.type),
      valid: validateString(form.type) ? 1 : 0,
    },
    instructions: {
      started: hasStarted(form.instructions),
      valid: validateString(form.instructions) ? 1 : 0,
    },
    subject: {
      started: hasStarted(form.subject),
      valid: form.type !== "email" || validateString(form.subject) ? 1 : 0,
    },
    variables: {
      started: true,
      valid: validateVariables(form.variables) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateTask = (form) => {
  const hasTemplate = form.template_id ? true : false;
  const isValid = {
    title: {
      started: hasStarted(form.title),
      valid: hasTemplate || validateString(form.title) ? 1 : 0,
    },
    type: {
      started: hasStarted(form.type),
      valid: hasTemplate || validateString(form.type) ? 1 : 0,
    },
    instructions: {
      started: hasStarted(form.instructions),
      valid: hasTemplate || validateString(form.instructions) ? 1 : 0,
    },
    start: {
      started: hasStarted(form.start),
      valid: validateString(form.start) ? 1 : 0,
    },
    delay: {
      started: hasStarted(form.delay),
      valid: !isNaN(form.delay) && parseInt(form.delay) >= 0 ? 1 : 0,
    },
    assigned_user_id: {
      started: hasStarted(form.assigned_user_id),
      valid:
        validateString(form.assigned_user_id) ||
        validateString(form.assigned_team_id)
          ? 1
          : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateCustomTask = (form) => {
  const hasTemplate = form.template_id ? true : false;
  const isValid = {
    title: {
      started: hasStarted(form.title),
      valid: hasTemplate || validateString(form.title) ? 1 : 0,
    },
    type: {
      started: hasStarted(form.type),
      valid: hasTemplate || validateString(form.type) ? 1 : 0,
    },
    instructions: {
      started: hasStarted(form.instructions),
      valid: hasTemplate || validateString(form.instructions) ? 1 : 0,
    },
    start: {
      started: hasStarted(form.start),
      valid: validateString(form.start) ? 1 : 0,
    },
    due_timestamp: {
      started: hasStarted(form.due_timestamp),
      valid:
        form.start === "now" ||
        (form.start === "start" && (form.due_timestamp || form.delay >= 0)) ||
        form.start === "previous"
          ? 1
          : 0,
    },
    assigned_user_id: {
      started: hasStarted(form.assigned_user_id),
      valid:
        validateString(form.assigned_user_id) ||
        validateString(form.assigned_team_id)
          ? 1
          : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateCustomCredit = (form) => {
  const isValid = {
    account_number: {
      started: hasStarted(form.account_number),
      valid: validateString(form.account_number) ? 1 : 0,
    },
    creditor: {
      started: hasStarted(form.creditor),
      valid: validateString(form.creditor) ? 1 : 0,
    },
    account_type: {
      started: hasStarted(form.account_type),
      valid: validateString(form.account_type) ? 1 : 0,
    },
    balance: {
      started: hasStarted(form.balance),
      valid: form.balance && parseInt(form.balance) > 0 ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateAccountRouting = (form, current) => {
  const isValid = {
    account: {
      started: hasStarted(form.account),
      valid: validateAccountNumber(form.account || "") ? 1 : 0,
    },
    reAccount: {
      started: hasStarted(form.reAccount),
      valid:
        validateAccountNumber(form?.reAccount) &&
        form?.account === form?.reAccount
          ? 1
          : 0,
    },
    bank: {
      started: hasStarted(form.bank),
      valid:
        validateString(form.bank || "") ||
        validateString(current.plaidBankName || "")
          ? 1
          : 0,
    },
    routing: {
      started: hasStarted(form.routing),
      valid: validateRoutingNumber(form.routing) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};
