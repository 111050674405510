import React from "react";
import { IconChevronLeft, IconEdit } from "@tabler/icons-react";
import { Tooltip, Button } from "@centrate-io/barn";
import { Link } from "react-router-dom";
import "./WorkflowHeader.scss";

function WorkflowHeader(props) {
  const { workflow } = props;

  let status = "saved";
  if (props.isEdited) status = "edited";

  return (
    <div className="workflow-header">
      {props.isEdited ? (
        <div className="awh-back disabled">
          <Tooltip title="Save before leaving">
            <span>
              <IconChevronLeft />
            </span>
          </Tooltip>
        </div>
      ) : (
        <div className="awh-back">
          <Link to="/settings/workflows">
            <span>
              <IconChevronLeft />
            </span>
          </Link>
        </div>
      )}

      <div className="awh-title">
        <h4 onClick={props.editName}>
          <span>Application&nbsp;&nbsp;/&nbsp;&nbsp;</span>
          <b>{workflow?.name}</b>
          <IconEdit />
        </h4>
        <div className={`awh-status-${status}`}>
          <span className="awh-circle" />
          <h5>{status.toUpperCase()}</h5>
        </div>
      </div>
      <div className="awh-actions">
        <Button
          type="blanco"
          size="small"
          className="boxed"
          disabled={!props.isEdited}
          onClick={props?.updateWorkflow}
          loading={props.saving}
        >
          Save Workflow
        </Button>
      </div>
    </div>
  );
}

export default WorkflowHeader;
