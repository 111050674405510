import { useState } from "react";
import { Toggle } from "_styleguide";
import { TAG_MAP } from "_constants/mapping";
import "./TemplatePreview.scss";

function TemplatePreview(props) {
  const [demo, setDemo] = useState(false);
  const { form } = props;

  const generateContent = () => {
    let content = form.instructions;
    if (demo) {
      form.variables.forEach((v) => {
        const variableToReplace = `{{${v.variable}}}`;
        const replaceWith = TAG_MAP[v.mapping]?.example;
        const regex = new RegExp(variableToReplace, "g");
        content = content.replace(regex, replaceWith);
      });
    }
    return content;
  };

  let preview = (
    <div className="html-preview">
      <iframe
        title="Email Preview"
        style={{ width: "100%", height: "600px", border: 0 }}
        srcDoc={generateContent()}
        sandbox="allow-same-origin"
      />
    </div>
  );

  return (
    <div className="ct-preview">
      {form.variables?.length > 0 ? (
        <span>
          Toggle variables?
          <Toggle
            size="small"
            onChange={(checked) => setDemo(checked)}
            checked={demo}
          />
        </span>
      ) : null}
      {preview}
    </div>
  );
}

export default TemplatePreview;
