import { gql } from "@apollo/client";

export const saveTaskTemplate = gql`
  mutation savePostGrid(
    $id: String
    $variables: [PostGridTemplateVariablesInput]!
  ) {
    savePostGrid(id: $id, variables: $variables)
  }
`;

export const deleteTemplate = gql`
  mutation deletePostGrid($template_id: String!) {
    deletePostGrid(template_id: $template_id)
  }
`;

export const savePostGridDraft = gql`
  mutation savePostGridDraft(
    $to: AddressBookInput!
    $from: AddressBookInput!
    $template_name: String
    $template_id: String!
    $customer_id: String
    $letter_id: String
    $variables: [PostGridVariablesValue]!
  ) {
    savePostGridDraft(
      to: $to
      from: $from
      template_name: $template_name
      template_id: $template_id
      customer_id: $customer_id
      letter_id: $letter_id
      variables: $variables
    ) {
      letter_id
      pdf_url
    }
  }
`;

export const cancelPostGridDraft = gql`
  mutation cancelPostGridDraft($letter_id: String!) {
    cancelPostGridDraft(letter_id: $letter_id)
  }
`;

export const sendPostGridDraft = gql`
  mutation sendPostGridDraft($letter_id: String!, $application_id: String) {
    sendPostGridDraft(letter_id: $letter_id, application_id: $application_id)
  }
`;
