import { gql } from "@apollo/client";

export const getPostGridTemplates = gql`
  query {
    getPostGridTemplates {
      id
      title
      type
      instructions
      variables {
        variable
        mapping
      }
      createdAt
      updatedAt
    }
  }
`;

export const getPostGridMailTemplates = gql`
  query getPostGridTemplates($application_id: String) {
    getPostGridTemplates(application_id: $application_id) {
      id
      title
      type
      instructions
      variables {
        variable
        mapping
      }
      customer {
        name
        first_name
        last_name
        co_name
        co_first_name
        co_last_name
        email
        phone
        dob
        ssn_last_4
        address
        address_line_two
        city
        state
        zip
      }
      createdAt
      updatedAt
    }
  }
`;

export const getPostGridPDF = gql`
  query getPostGridPDF($letter_id: String!) {
    getPostGridPDF(letter_id: $letter_id)
  }
`;

// "id": "template_c6HSqnsD1h2zoeHJ6Z9EEA",
// "object": "template",
// "live": false,
// "deleted": false,
// "description": "Test",
// "html": "<b>Hello</b> updated world!",
// "createdAt": "2020-11-12T23:15:46.750Z",
// "updatedAt": "2020-11-12T23:16:36.142Z"
