import { gql } from "@apollo/client";

export const adminMail = gql`
  query adminMail(
    $id: ID
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
  ) {
    adminMail(
      id: $id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      data {
        id
        status
        to {
          name
          address
          address_line_two
          city
          state
          zip
          country
        }
        from {
          name
          address
          address_line_two
          city
          state
          zip
          country
        }
        description
        postgrid_id
        postgrid_pdf_url
        createdAt

        user {
          id
          name
        }

        task {
          id
          title
        }
      }
      total
      pageTotal
    }
  }
`;
