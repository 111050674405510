import { Text, Button } from "@centrate-io/barn";
import { TaskBadge } from "_styleguide";
import { stripHTML } from "_assets/js/helpers";
import moment from "moment";
import { extractVariables } from "_assets/js/helpers";

const postGridVariables = [
  "to.companyName",
  "to.addressLine1",
  "to.addressLine2",
  "to.city",
  "to.provinceOrState",
  "to.postalOrZip",
  "from.companyName",
  "from.addressLine1",
  "from.addressLine2",
  "from.city",
  "from.provinceOrState",
  "from.postalOrZip",
];

export const getColumns = (readOnly, showNewTask, editTemplate) => {
  return [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      render: (type) => (
        <div className="task-badge-type">
          <TaskBadge full="true" type={type} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      render: (title) => (
        <Text>
          <b>{title}</b>
        </Text>
      ),
    },
    {
      title: "Preview",
      dataIndex: "instructions",
      key: "instructions",
      render: (instructions) => {
        const trimmed = stripHTML(
          instructions?.replaceAll(/\n/g, "").replaceAll("  ", "") || "",
        )?.trim();
        if (trimmed?.length > 43) {
          const stripped = trimmed.slice(0, 40);
          return <Text>{stripped}...</Text>;
        } else if (trimmed?.length > 0) {
          return <Text>{trimmed}</Text>;
        } else {
          return (
            <Text>
              <i>n/a</i>
            </Text>
          );
        }
      },
    },
    {
      title: "Variables",
      dataIndex: "variables",
      key: "variables",
      width: 100,
      render: (variables, data) => {
        const variableData = extractVariables(data.instructions || "");
        const totalVariables = variableData?.length || 0;
        const totalMapped =
          variables?.filter((v) => {
            const isPostGrid = postGridVariables?.includes(v.variable);
            return v.mapping || isPostGrid ? true : false;
          })?.length || 0;
        return (
          <Text>
            {totalMapped}/{totalVariables}
          </Text>
        );
      },
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) => (
        <Text>{moment(updatedAt).format("MMM D, YYYY")}</Text>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      width: 100,
      align: "center",
      fixed: "right",
      render: (address, data) => {
        const variableData = extractVariables(data.instructions || "");
        return variableData?.length > 0 ? (
          <div className="table-actions">
            <Button
              type="blanco"
              size="small"
              className="boxed"
              onClick={() => editTemplate(data)}
            >
              Map Variables
            </Button>
          </div>
        ) : (
          ""
        );
      },
    },
  ];
};
